import { isVideo } from "@utils/image";
import { escapeReact } from "@utils/react";

export default ({ imgParams, i18n, pathfinder }) => {
  const _i18n = i18n.pages.AssemblyGuide;

  const escapeReactRoute = str => escapeReact(str, pathfinder);

  const buildImgSrc = imgName =>
    imgParams.imgDir + "/pages/assembly-guide/".concat(imgName);

  const buildImg = (imgName, sizes, aspect) =>
    imgName
      ? {
          ...(isVideo(imgName)
            ? {
                url: imgName,
                embededVideo: true,
                allowfullscreen: true,
                sizes
              }
            : {
                src: buildImgSrc(imgName),
                cloudinary: imgParams.cloudinary,
                sizes,
                aspect,
                version: true
              })
        }
      : null;

  return {
    lead: {
      header: _i18n.lead.HEADER,
      title: _i18n.lead.TITLE,
      text: escapeReactRoute(_i18n.lead.TEXT)
    },
    installationGas: {
      lead: {
        title: _i18n.installationGas.lead.TITLE,
        text: escapeReactRoute(_i18n.installationGas.lead.TEXT),
        img: buildImg(
          _i18n.installationGas.lead.IMG,
          {
            mobilePortrait: 272,
            mobileLandscape: 272,
            tabletPortrait: 272,
            tabletLandscape: 200,
            desktop: 272
          },
          0.4522
        )
      },
      paragraphs: (_i18n.installationGas.paragraphs || []).map(item => ({
        title: item.TITLE,
        text: escapeReactRoute(item.TEXT),
        img: item.IMG
          ? buildImg(
              item.IMG,
              {
                mobilePortrait: 345,
                mobileLandscape: 452,
                tabletPortrait: 295,
                tabletLandscape: 415,
                desktop: 520
              },
              0.351
            )
          : null
      })),
      otherParagraphs: (_i18n.installationGas.otherParagraphs || []).map(
        item => ({
          title: item.TITLE,
          text: escapeReactRoute(item.TEXT),
          img: item.IMG
            ? buildImg(
                item.IMG,
                {
                  mobilePortrait: 345,
                  mobileLandscape: 452,
                  tabletPortrait: 295,
                  tabletLandscape: 415,
                  desktop: 520
                },
                0.351
              )
            : null
        })
      )
    },
    installationElectric: {
      lead: {
        title: _i18n.installationElectric.lead.TITLE,
        text: escapeReactRoute(_i18n.installationElectric.lead.TEXT),
        img: buildImg(
          _i18n.installationElectric.lead.IMG,
          {
            mobilePortrait: 272,
            mobileLandscape: 272,
            tabletPortrait: 272,
            tabletLandscape: 200,
            desktop: 272
          },
          0.4522
        )
      }
    },
    shouldDoYourself: {
      lead: {
        title: _i18n.shouldDoYourself.lead.TITLE,
        text: escapeReactRoute(_i18n.shouldDoYourself.lead.TEXT),
        img: buildImg(
          _i18n.shouldDoYourself.lead.IMG,
          {
            mobilePortrait: 272,
            mobileLandscape: 272,
            tabletPortrait: 272,
            tabletLandscape: 200,
            desktop: 272
          },
          0.4522
        )
      }
    },
    placingRangeCooker: {
      lead: {
        title: _i18n.placingRangeCooker.lead.TITLE,
        text: escapeReactRoute(_i18n.placingRangeCooker.lead.TEXT),
        img: buildImg(
          _i18n.placingRangeCooker.lead.IMG,
          {
            mobilePortrait: 272,
            mobileLandscape: 272,
            tabletPortrait: 272,
            tabletLandscape: 200,
            desktop: 272
          },
          0.4522
        )
      },
      paragraphs: (_i18n.placingRangeCooker.paragraphs || []).map(item => ({
        title: item.TITLE,
        text: escapeReactRoute(item.TEXT),
        img: item.IMG
          ? buildImg(
              item.IMG,
              {
                mobilePortrait: 345,
                mobileLandscape: 452,
                tabletPortrait: 295,
                tabletLandscape: 415,
                desktop: 520
              },
              0.351
            )
          : null
      }))
    },
    faq: {
      lead: {
        title: _i18n.faq.lead.TITLE,
        text: escapeReactRoute(_i18n.faq.lead.TEXT)
      },
      items: _i18n.faq.items.map(item => ({
        title: item.TITLE,
        text: escapeReactRoute(item.TEXT)
      }))
    }
  };
};
