// It's funnay but we could avoid all this hassle if we could import the src/stylesheets/sites/91.scss dynamically at runtime.
// Unfortunately a SCSS file needs to be NodeJS-compiled, so SCSS cannot be dynamically imported at the browser level.

// the site specific Cloudinary settings
//import cloudinary from "./cloudinary.json"; //TBD

import { mergeGraphQLClientArgs, mergeSiteConfig } from "../";

// compiled/bind at build-time by node-sass
import "../../stylesheets/sites/91.scss";

// SITE-DEPENDENT complex template props (cannot be defined statically, as JSONs)
import AssemblyGuideAnchors from "./AssemblyGuideAnchors";
import AssemblyGuide from "./AssemblyGuide";
import BuyGuide from "./BuyGuide";
import BuyGuideAnchors from "./BuyGuideAnchors";

// SITE-DEPENDENT simple template props (defined statically as JSONs)
import i18n from "./i18n";
import siteRedirects from "./json/redirects.json";
import siteScreens from "./json/screens.json";
import siteTemplates from "./json/templates.json";

export const getGraphQLClientArgs = siteId =>
  mergeGraphQLClientArgs(siteId, i18n);

export default siteId =>
  mergeSiteConfig(siteId, {
    AssemblyGuide,
    AssemblyGuideAnchors,
    BuyGuide,
    BuyGuideAnchors,
    //
    i18n,
    siteRedirects,
    siteScreens,
    siteTemplates
  });
